// import React from "react";
// import { HashLink as Link } from 'react-router-hash-link';

// const Navbar = () => {

//   return (
//     <nav className="bg-consilium-primary p-4">
//       <div className="container mx-auto flex justify-between items-center font-poppins">
//         <Link to="/" className="flex items-center">
//           <img 
//             src="/greenwood_capital_logo.webp" 
//             alt="Logo" 
//             className="h-8 md:h-10 lg:h-12"
//           />
//         </Link>
//         <div className="flex space-x-16">
//           <Link to="/" className="text-white hover:text-gray-300">Home</Link>
//           <Link to="/" className="text-call-to-action hover:text-gray-300">Apply</Link>
//           <Link to="/about" className="text-white hover:text-gray-300">About</Link>
//           <Link to="/products" className="text-white hover:text-gray-300">Products</Link>
//           <Link to="/guides" className="text-white hover:text-gray-300">Guides</Link>
//           <Link to="/blog" className="text-white hover:text-gray-300">Blog</Link>
//           <Link to="/contact-us" className="text-white hover:text-gray-300">Contact Us</Link>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

import React, { useState } from "react";
import { HashLink as Link } from 'react-router-hash-link';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    console.log('toggling menu');
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-consilium-primary p-4 z-50 w-full">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <img 
            src="/greenwood_capital_logo.webp" 
            alt="Logo" 
            className="h-8 md:h-10 lg:h-12"
          />
        </Link>
        <div className="block lg:hidden">
          <button 
            onClick={toggleMenu} 
            className="text-white focus:outline-none"
          >
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
            </svg>
          </button>
        </div>
        <div className={`lg:flex lg:space-x-4 fixed top-0 right-0 w-2/3 h-full bg-consilium-primary transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} font-poppins transition-transform duration-300 ease-in-out lg:relative lg:translate-x-0 lg:w-auto lg:h-auto lg:bg-transparent lg:transition-none z-50`}>
          <button 
            onClick={toggleMenu} 
            className="absolute top-4 right-4 text-white focus:outline-none lg:hidden"
          >
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
          <div className="flex flex-col lg:flex-row lg:items-center lg:space-x-16 mt-16 lg:mt-0 font-poppins">
            <Link to="https://www.greenwoodcapital.co.uk/" className="text-white hover:text-gray-300 py-2 px-4 lg:p-0">Home</Link>
            <Link to="/" className="text-call-to-action hover:text-gray-300 py-2 px-4 lg:p-0">Apply</Link>
            <Link to="https://www.greenwoodcapital.co.uk/about" className="text-white hover:text-gray-300 py-2 px-4 lg:p-0">About</Link>
            {/* <Link to="/products" className="text-white hover:text-gray-300 py-2 px-4 lg:p-0">Products</Link> */}
            <Link to="https://www.greenwoodcapital.co.uk/guides" className="text-white hover:text-gray-300 py-2 px-4 lg:p-0">Guides</Link>
            <Link to="https://www.greenwoodcapital.co.uk/insights" className="text-white hover:text-gray-300 py-2 px-4 lg:p-0">Blog</Link>
            <Link to="https://www.greenwoodcapital.co.uk/contact-us" className="text-white hover:text-gray-300 py-2 px-4 lg:p-0">Contact Us</Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
