// import React, { useEffect, useState } from 'react';

// const getAddressDomainToken = 'Vkxbhjby00-XzuyhwFp7BA43108';



// const AddressFields = ({ userInput, setUserInput, setIsAddressValid }) => {
//   const [errors, setErrors] = useState({});

//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://cdn.getaddress.io/scripts/getaddress-autocomplete-1.3.4.min.js';
//     script.async = true;
//     script.onload = () => {
//       if (window.getAddress) {
//         window.getAddress.autocomplete('formatted_address_0', getAddressDomainToken);
//       }
//     };
//     document.body.appendChild(script);

//     const handleAddressSelected = (e) => {
//       console.log('selected address:', e);
//       const address = e.address;
//       const formattedAddress = address.formatted_address;
//       // const { formatted_address_0, formatted_address_1, town_or_city, county, postcode } = e.address;
//       const buildingNumber = address.building_number;
//       const buildingName = address.building_name;
//       const formatted_address_0 = formattedAddress[0];
//       const formatted_address_1 = formattedAddress[1];
//       const town_or_city = address.town_or_city;
//       const county = address.county;
//       const postcode = address.postcode;
//       const country = address.country;

//       // save to storage now?
//       let userParams = JSON.parse(localStorage.getItem('userParams')) || {};
//       userParams['fullAddress'] = JSON.stringify(address);
//       localStorage.setItem('userParams', JSON.stringify(userParams));

//       setUserInput({
//         buildingNumber,
//         buildingName,
//         formatted_address_0,
//         formatted_address_1,
//         town_or_city,
//         county,
//         postcode,
//         country
//       });

//       setIsAddressValid(!!formatted_address_0);
//     }; 

//     document.addEventListener('getaddress-autocomplete-address-selected', handleAddressSelected);

   
//     return () => {
//       // Clean up the script when the component unmounts
//       document.body.removeChild(script);
//       document.removeEventListener('getaddress-autocomplete-address-selected', handleAddressSelected);
//     };
//   }, [setUserInput, setIsAddressValid]);

//   const handleInputChange = (e) => {
//     const { id, value } = e.target;
//     console.log('update:', id, value);
//     setUserInput((prevInput) => ({
//       ...prevInput,
//       [id]: value,
//     }));
//     console.log('userInput', userInput);
//     // Remove error for the field being changed
//     setErrors((prevErrors) => ({
//       ...prevErrors,
//       [id]: value ? '' : prevErrors[id],
//     }));
//   };

//   const validateFields = () => {
//     const newErrors = {};
//     if (!userInput.formatted_address_0) {
//       newErrors.formatted_address_0 = 'Address 1 is required';
//     }
//     if (!userInput.postcode) {
//       newErrors.postcode = 'Postcode is required';
//     }
//     setErrors(newErrors);
//     return Object.keys(newErrors).length === 0;
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (validateFields()) {
//       // Proceed with form submission
//       console.log('Form submitted:', userInput);
//     } else {
//       console.log('Validation errors:', errors);
//     }
//   };


//   return (
//     <form className="top-2/3 w-full flex flex-col items-center space-y-6" onSubmit={handleSubmit}>
//       <input
//         id="formatted_address_0"
//         type="text"
//         value={userInput.formatted_address_0 || ''}
//         onChange={handleInputChange}
//         className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
//         placeholder="Address 1"
//       />
//       {errors.formatted_address_0 && (
//         <div className="text-red-500 text-sm">{errors.formatted_address_0}</div>
//       )}

//       <input
//         id="formatted_address_1"
//         type="text"
//         value={userInput.formatted_address_1 || ''}
//         onChange={handleInputChange}
//         className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
//         placeholder="Address 2"
//       />

//       <input
//         id="town_or_city"
//         type="text"
//         value={userInput.town_or_city || ''}
//         onChange={handleInputChange}
//         className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
//         placeholder="Town"
//       />

//       <input
//         id="county"
//         type="text"
//         value={userInput.county || ''}
//         onChange={handleInputChange}
//         className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
//         placeholder="County"
//       />

//       <input
//         id="postcode"
//         type="text"
//         value={userInput.postcode || ''}
//         onChange={handleInputChange}
//         className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
//         placeholder="Postcode"
//       />
//       {errors.postcode && (
//         <div className="text-red-500 text-sm">{errors.postcode}</div>
//       )}

//       {/* <button type="submit" className="bg-blue-500 text-white p-2 rounded">
//         Submit
//       </button> */}
//     </form>
//   );
// };

// export default AddressFields;

import React, { useEffect, useState } from 'react';

const getAddressDomainToken = 'Vkxbhjby00-XzuyhwFp7BA43108';

const AddressFields = ({ userInput, setUserInput, setIsAddressValid }) => {
  const [errors, setErrors] = useState({});
  const [showAddressDetails, setShowAddressDetails] = useState(false); // To control visibility of additional fields

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.getaddress.io/scripts/getaddress-autocomplete-1.3.4.min.js';
    script.async = true;
    script.onload = () => {
      if (window.getAddress) {
        window.getAddress.autocomplete('formatted_address_0', getAddressDomainToken);
      }
    };
    document.body.appendChild(script);

    const handleAddressSelected = (e) => {
      console.log('selected address:', e);
      const address = e.address;
      const formattedAddress = address.formatted_address;
      const buildingNumber = address.building_number;
      const buildingName = address.building_name;
      const formatted_address_0 = formattedAddress[0];
      const formatted_address_1 = formattedAddress[1];
      const town_or_city = address.town_or_city;
      const county = address.county;
      const postcode = address.postcode;
      const country = address.country;

      // Save to localStorage
      let userParams = JSON.parse(localStorage.getItem('userParams')) || {};
      userParams['fullAddress'] = JSON.stringify(address);
      localStorage.setItem('userParams', JSON.stringify(userParams));

      setUserInput({
        buildingNumber,
        buildingName,
        formatted_address_0,
        formatted_address_1,
        town_or_city,
        county,
        postcode,
        country,
      });

      setIsAddressValid(!!formatted_address_0);
      setShowAddressDetails(true); // Show additional fields when an address is selected
    };

    document.addEventListener('getaddress-autocomplete-address-selected', handleAddressSelected);

    return () => {
      // Clean up the script and event listener when the component unmounts
      document.body.removeChild(script);
      document.removeEventListener('getaddress-autocomplete-address-selected', handleAddressSelected);
    };
  }, [setUserInput, setIsAddressValid]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setUserInput((prevInput) => ({
      ...prevInput,
      [id]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [id]: value ? '' : prevErrors[id],
    }));
  };

  const validateFields = () => {
    const newErrors = {};
    if (!userInput.formatted_address_0) {
      newErrors.formatted_address_0 = 'Address 1 is required';
    }
    if (!userInput.postcode) {
      newErrors.postcode = 'Postcode is required';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateFields()) {
      console.log('Form submitted:', userInput);
    } else {
      console.log('Validation errors:', errors);
    }
  };

  return (
    <form className="top-2/3 w-full flex flex-col items-center space-y-6" onSubmit={handleSubmit}>
      <input
        id="formatted_address_0"
        type="text"
        value={userInput.formatted_address_0 || ''}
        onChange={handleInputChange}
        className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
        placeholder="Residential Address"
      />
      {errors.formatted_address_0 && (
        <div className="text-red-500 text-sm">{errors.formatted_address_0}</div>
      )}

      {/* Conditionally render the rest of the fields */}
      {showAddressDetails && (
        <>
          <input
            id="formatted_address_1"
            type="text"
            value={userInput.formatted_address_1 || ''}
            onChange={handleInputChange}
            className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
            placeholder="Address 2"
          />

          <input
            id="town_or_city"
            type="text"
            value={userInput.town_or_city || ''}
            onChange={handleInputChange}
            className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
            placeholder="Town"
          />

          <input
            id="county"
            type="text"
            value={userInput.county || ''}
            onChange={handleInputChange}
            className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
            placeholder="County"
          />

          <input
            id="postcode"
            type="text"
            value={userInput.postcode || ''}
            onChange={handleInputChange}
            className="border-b-2 text-base sm:text-xl p-2 focus:outline-none bg-white"
            placeholder="Postcode"
          />
          {errors.postcode && (
            <div className="text-red-500 text-sm">{errors.postcode}</div>
          )}
        </>
      )}
    </form>
  );
};

export default AddressFields;
